<template>
  <div>
    <el-form style="width: 100%">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请输入学生姓名">
            <el-input placeholder="请输入学生姓名" v-model="searchData.name" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入学生手机号码">
            <el-input placeholder="请输入手机号" v-model="searchData.phone" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入学生身份证号码">
            <el-input
              placeholder="请输入学生身份证号码"
              v-model="searchData.id_card"
            />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="院系名称">
            <el-select v-model="searchData.domain_id" placeholder="请选择院系">
              <el-option
                v-for="item in professionalDropDown"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="所属专业" prop="domain_id_child">
            <el-select
              v-model="searchData.domain_id_child"
              placeholder="请选择专业"
              @focus="onTheirProfession(searchData.domain_id)"
            >
              <el-option
                v-for="item in theirProfession"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="层次" prop="name">
            <el-select
              v-model="searchData.length"
              placeholder="请选择层次"
              @focus="onLayerPullDown()"
            >
              <el-option
                v-for="item in hierarchicalData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入学生性别" prop="name">
            <el-input v-model="searchData.sex"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="缴费状态">
            <el-select
              placeholder="请选择缴费状态"
              v-model="searchData.refundState"
            >
              <el-option value="1" label="已缴费"> </el-option>
              <el-option value="2" label="已退费"> </el-option>
              <el-option value="3" label="未支付"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请输入学生毕业所在毕业县区" prop="name">
            <el-input v-model="searchData.graduation_address"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入地址" prop="name">
            <el-input v-model="searchData.addr"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入经办人" prop="name">
            <el-input v-model="searchData.manager"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入经办人部门" prop="name">
            <el-input v-model="searchData.manager_dept"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="选择报名时间">
            <el-date-picker
              v-model="startEnd"
              type="daterange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="选择缴费时间">
            <el-date-picker
              v-model="payTheFeesEnd"
              type="daterange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="选择退费时间">
            <el-date-picker
              v-model="refundTime"
              type="daterange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format="YYYY/MM/DD"
              value-format="YYYY-MM-DD"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="2">
          <el-form-item>
            <el-button type="primary" @click="searchBtn"> 搜索 </el-button>
          </el-form-item>
        </el-col>

        <el-col :span="2">
          <el-form-item>
            <el-button type="success" @click="onExportFn"> 导出 </el-button>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <el-button type="success" @click="onDistrictAndCountyExport">
              区县级统计导出
            </el-button>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <el-button type="success" @click="onDepartmentsSpecializedExport">
              系部专业统计导出
            </el-button>
          </el-form-item>
        </el-col>

        <el-col :span="4">
          <el-form-item>
            <el-button type="success" @click="onExportOfTheDepartment">
              系部经办人统计导出
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格数据 -->

    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="已通过" name="first">
        <el-table
          :data="datas.tableData"
          border
          style="width: 100%"
          :header-cell-style="{ background: '#f2f2f2' }"
        >
          <el-table-column prop="number" fixed label="报名序号" width="120" />
          <el-table-column prop="name" fixed label="报名姓名" width="100" />
          <el-table-column prop="sex" label="性别" width="100" />
          <el-table-column prop="nation" label="民族" width="100" />
          <el-table-column prop="education" label="学历" width="100" />
          <el-table-column prop="id_card" label="身份证" width="180" />
          <el-table-column prop="phone" label="手机号" width="150" />
          <el-table-column prop="college_name" label="院系" width="180" />
          <el-table-column prop="domain_name" label="专业" width="180" />
          <el-table-column prop="level_name" label="学制" width="100" />
          <el-table-column prop="addr" label="地址" width="180" />
          <el-table-column prop="parent_name" label="家长姓名" width="100" />
          <el-table-column prop="parent_phone" label="家长手机号" width="150" />
          <el-table-column prop="grades" label="中考成绩" width="100" />
          <el-table-column
            prop="graduated_school"
            label="毕业院校"
            width="180"
          />
          <el-table-column
            prop="graduation_class"
            label="毕业班级"
            width="100"
          />
          <el-table-column
            prop="graduation_address"
            label="毕业学校所在县区"
            width="150"
          />
          <el-table-column
            prop="graduating_class_teacher"
            label="毕业班主任"
            width="100"
          />
          <el-table-column prop="manager" label="经办人" width="80" />
          <el-table-column prop="manager_dept" label="经办部门" width="100" />
          <el-table-column prop="create_time" label="填写时间" width="180" />
          <el-table-column label="处理状态" width="100">
            <template v-slot="{ row }">
              {{ processingStatusEcho[row.status] }}
            </template>
          </el-table-column>
          <el-table-column prop="book_img" label="录取通知书" width="100">
            <template #default="{ row }">
              <el-image
                @click="
                  bigImg(`https://lnjsxy.college.yslts.com/${row.book_img}`)
                "
                style="width: 80px; height: 50px"
                :preview-teleported="true"
                :preview-src-list="srcList"
                :src="'https://lnjsxy.college.yslts.com/' + row.book_img"
                fit="cover"
              />
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="处理备注" width="100" />
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            width="320"
          >
            <template #default="{ row }">
              <el-button type="primary" size="small" @click="onEditBtn(row)">
                修改</el-button
              >

              <el-button
                type="danger"
                v-if="datas.roleData === 0"
                size="small"
                @click="onBtnDelete(row.id)"
              >
                删除</el-button
              >

              <el-button
                type="primary"
                size="small"
                @click="onRechargeNotice(row.id)"
              >
                重置录取通知书</el-button
              >
              <el-button type="primary" size="small" @click="onPrint(row.id)">
                打印</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="未通过" name="second">
        <el-table
          :data="datas.tableData"
          border
          style="width: 100%"
          :header-cell-style="{ background: '#f2f2f2' }"
        >
          <el-table-column prop="number" fixed label="报名序号" width="120" />
          <el-table-column prop="name" fixed label="报名姓名" width="100" />
          <el-table-column prop="sex" label="性别" width="100" />
          <el-table-column prop="nation" label="民族" width="100" />
          <el-table-column prop="education" label="学历" width="100" />
          <el-table-column prop="id_card" label="身份证" width="180" />
          <el-table-column prop="phone" label="手机号" width="150" />
          <el-table-column prop="college_name" label="院系" width="180" />
          <el-table-column prop="domain_name" label="专业" width="180" />
          <el-table-column prop="level_name" label="学制" width="100" />
          <el-table-column prop="addr" label="地址" width="180" />
          <el-table-column prop="parent_name" label="家长姓名" width="100" />
          <el-table-column prop="parent_phone" label="家长手机号" width="150" />
          <el-table-column prop="grades" label="中考成绩" width="100" />
          <el-table-column
            prop="graduated_school"
            label="毕业院校"
            width="180"
          />
          <el-table-column
            prop="graduation_class"
            label="毕业班级"
            width="100"
          />
          <el-table-column
            prop="graduation_address"
            label="毕业学校所在县区"
            width="150"
          />
          <el-table-column
            prop="graduating_class_teacher"
            label="毕业班主任"
            width="100"
          />
          <el-table-column prop="manager" label="经办人" width="80" />
          <el-table-column prop="manager_dept" label="经办部门" width="100" />
          <el-table-column prop="create_time" label="填写时间" width="180" />
          <el-table-column label="处理状态" width="100">
            <template v-slot="{ row }">
              {{ processingStatusEcho[row.status] }}
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="处理备注" width="100" />
          <el-table-column
            label="操作"
            align="center"
            fixed="right"
            width="240"
          >
            <template #default="{ row }">
              <el-button type="primary" size="small" @click="onEditBtn(row)">
                修改</el-button
              >

              <el-button
                type="danger"
                size="small"
                v-if="datas.roleData === 0"
                @click="onBtnDelete(row.id)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[5, 10, 20, 50, 100]"
          :page-size="searchData.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="searchData.total"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import {
  auditedAPI,
  reviewExportAPI,
  departmentsSpecializedExportAPI,
  districtAndCountyExportAPI,
  exportOfTheDepartmentAPI,
  reviewDeleteAPI,
  rechargeNoticeAPI
} from '@/api/reviewed'
import {
  listOfSelectionOfHospitalsAPI,
  professionalSelectionListAPI,
  listOfHierarchicalListingAPI
} from '@/api/specialized'
import { ElMessage, ElMessageBox } from 'element-plus'
import { userSCornerListAPI } from '@/api/organization'
import { userStore } from '@/store'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const activeName = ref('first')

const searchData = ref({
  page: 1, // 当前页
  total: 0, // 总数
  limit: 5, //  每页几条数据
  phone: '', //  手机号
  name: '', //  学生姓名
  id_card: '', // 身份证
  start: '', // 开始时间
  end: '', // 结束时间
  domain_id: '',
  length: '',
  status: '1', // 审核状态
  sex: '', // 性别
  graduation_address: '', // 毕业县区
  addr: '', // 请输入地址
  manager: '',
  manager_dept: '',
  searchData: '',
  paytime_start: '',
  paytime_end: '',
  upTimeStart: '',
  upTimeEnd: '',
  role: ''
})
const startEnd = ref([])
// 缴费时间
const payTheFeesEnd = ref([])
watch(
  () => payTheFeesEnd.value,
  (val) => {
    if (val) {
      searchData.value.paytime_start = val[0]
      searchData.value.paytime_end = val[1]
    } else {
      searchData.value.paytime_start = ''
      searchData.value.paytime_end = ''
    }
  },
  {
    deep: true
  }
)

// 退费时间
const refundTime = ref([])
watch(
  () => refundTime.value,
  (val) => {
    if (val) {
      searchData.value.upTimeStart = val[0]
      searchData.value.upTimeEnd = val[1]
    } else {
      searchData.value.upTimeStart = ''
      searchData.value.upTimeEnd = ''
    }
  },
  {
    deep: true
  }
)
const searchBtn = () => {
  if (startEnd.value) {
    searchData.value.start = startEnd.value[0]
    searchData.value.end = startEnd.value[1]
  } else {
    searchData.value.start = ''
    searchData.value.end = ''
  }
  console.log('tableData.valuesearchBtnsearchBtn', datas.value.roleData)
  onToBeReviewed()
}

// 院系
const professionalDropDown = ref([])
const onListOfSelectionOfHospitals = async () => {
  const data = await listOfSelectionOfHospitalsAPI()

  professionalDropDown.value = data
}
onListOfSelectionOfHospitals()

// 专业
// 所属专业
const theirProfession = ref([])
const onTheirProfession = async (id) => {
  if (id) {
    const data = await professionalSelectionListAPI({
      college_id: id
    })
    theirProfession.value = data
  } else {
    ElMessage.error('请先选择系部')
  }
}

// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}

const processingStatusEcho = {
  0: '待审核',
  1: '通过',
  2: '不通过'
}

// 导出
const onExportFn = async () => {
  const data = await reviewExportAPI(searchData.value)
  window.location.href = `https://lnjsxy.college.yslts.com${data}`
}

// 系部-专业-报名人数统计导出
const onDistrictAndCountyExport = async () => {
  if (startEnd.value.length !== 2) {
    return ElMessage.error('起始时间与结束时间不可以为空')
  }

  const data = await districtAndCountyExportAPI({
    start_time: startEnd.value[0],
    end_time: startEnd.value[1]
  })
  if (!data) return ElMessage.error('当前时间段没有数据')
  window.location.href = `https://lnjsxy.college.yslts.com${data}`
}

// 系部-专业-报名人数统计导出
const onDepartmentsSpecializedExport = async () => {
  if (startEnd.value.length !== 2) {
    return ElMessage.error('起始时间与结束时间不可以为空')
  }

  const data = await departmentsSpecializedExportAPI({
    start_time: startEnd.value[0],
    end_time: startEnd.value[1]
  })
  if (!data) return ElMessage.error('当前时间段没有数据')
  window.location.href = `https://lnjsxy.college.yslts.com${data}`
}

// 系部-经办人-报名人数统计导出
const onExportOfTheDepartment = async () => {
  if (startEnd.value.length !== 2) {
    return ElMessage.error('起始时间与结束时间不可以为空')
  }

  const data = await exportOfTheDepartmentAPI({
    start_time: startEnd.value[0],
    end_time: startEnd.value[1]
  })
  if (!data) return ElMessage.error('当前时间段没有数据')
  window.location.href = `https://lnjsxy.college.yslts.com${data}`
}

/** 表单 E */
/** 表格 S */

// tabs
const handleClick = (tab) => {
  console.log(tab.index)

  switch (Number(tab.index)) {
    case 0:
      searchData.value.status = 1
      break
    case 1:
      searchData.value.status = 2
      break
    case 2:
      searchData.value.status = 0
      break

    default:
      break
  }
  onToBeReviewed()
}

// 照片
const srcList = ref([])
const bigImg = (url) => {
  srcList.value[0] = url
}

// 删除
const onBtnDelete = (id) => {
  ElMessageBox.alert('是否删除该条记录 ?', '提示', {
    // if you want to disable its autofocus
    // autofocus: false,
    confirmButtonText: '确认',
    callback: async () => {
      await reviewDeleteAPI({ id })
      ElMessage.success('信息删除成功 !')
      onToBeReviewed()
    }
  })
}

// 重置通知书
const onRechargeNotice = (id) => {
  ElMessageBox.alert('是否重置该录取通知书 ?', '提示', {
    // if you want to disable its autofocus
    // autofocus: false,
    confirmButtonText: '确认',
    callback: async () => {
      await rechargeNoticeAPI({ id })
      ElMessage.success('通知书重置成功 !')
      onToBeReviewed()
    }
  })
}

// 打印
const onPrint = (id) => {
  router.push(`/signUp/print/${id}`)
}
// 当前用户具有的角色
const userRoleTitleList = ref([])
const store = userStore()

// 表格
// const tableData = ref([])
const datas = ref({
  tableData: [],
  roleData: []
})
const onToBeReviewed = async () => {
  const res = await userSCornerListAPI({ id: store.userid })
  // 数据回显
  userRoleTitleList.value = res.map((item) => item.role_name)
  if (userRoleTitleList.value.includes('超级管理员')) {
    searchData.value.role = '0' // 如果用户具有超级管理员角色，将role设置为0
  } else {
    searchData.value.role = '1' // 否则将role设置为1
  }
  console.log('searchData.value.role', searchData.value.role)
  const { data, total } = await auditedAPI(searchData.value)
  datas.value.tableData = data
  searchData.value.total = total
  datas.value.roleData = searchData.value.role
  console.log('tableData.value', datas.value.roleData)
}

onToBeReviewed()
const router = useRouter()
const onEditBtn = ({ id }) => {
  router.push(`/signUp/reviseInformation/${id}`)
}
/** 表格 E */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  searchData.value.limit = val
  onToBeReviewed()
}

// 页码值
const handleCurrentChange = (val) => {
  searchData.value.page = val
  onToBeReviewed()
}

/**
 *  分页 E
 */
</script>

<style lang="scss" scoped>
::v-deep .el-select {
  width: 100%;
}
</style>
